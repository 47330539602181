<template>
    <div class="ovt">
        <OrbitObjectsConfiguration :tool="tool" />
        <VisualisationSimulation :tool="tool">
            <OrbitPerturbedLegend />
            <VisualisationObjectLegend />
        </VisualisationSimulation>
        <VisualisationAxesViewer />
        <VisualisationRecorder />
        <VisualisationSettings :tool="tool" />
        <OrbitTimeline />
    </div>
</template>

<script>
import moment from "moment"
import OrbitObjectsConfiguration from '@/components/ovt/OrbitObjectsConfiguration'
import OrbitPerturbedLegend from '@/components/ovt/OrbitPerturbedLegend'
import VisualisationSimulation from '@/components/common/visualisation/Simulation'
import VisualisationRecorder from '@/components/common/visualisation/Recorder'
import VisualisationObjectLegend from '@/components/common/visualisation/ObjectLegend'
import VisualisationSettings from '@/components/common/visualisation/Settings'
import OrbitTimeline from '@/components/ovt/OrbitTimeline'
import VisualisationAxesViewer from '@/components/common/visualisation/AxesViewer'
import CommonActions from '@/store/common/common-actions'
import SyntheticObjectsService from '@/services/synthetic-objects.service'
import TimeFrameService from '@/services/timeframe.service'
import VisualisationService from '@/services/visualisation.service'

export default {
    name: 'OrbitVisualisationTool',
    components: {
        OrbitObjectsConfiguration,
        VisualisationRecorder,
        OrbitPerturbedLegend,
        VisualisationObjectLegend,
        VisualisationSettings,
        OrbitTimeline,
        VisualisationSimulation,
        VisualisationAxesViewer
    },
    props: {
        tool: String,
    },
    data() {
        return {
            currentStartTime: null,
            currentEndTime: null,
            currentDate: null,
        };
    },
    computed: {
        selectedTimeframe() {
            return TimeFrameService.getTimeFrame().converted;
        },
        simulationTime() {
            return TimeFrameService.getSimulationTime();
        },
    },
    watch: {
        selectedTimeframe(timeframe) {
            if (
                (this.currentStartTime !== timeframe.start &&
                    timeframe.start) ||
                (this.currentEndTime !== timeframe.end && timeframe.end)
            ) {
                this.currentStartTime = timeframe.start;
                this.currentEndTime = timeframe.end;
                SyntheticObjectsService.checkPerturbedSyntheticObjects();
            }
        },
        simulationTime(newDate) {
            const date = this.formatDate(newDate);
            if (this.currentDate === date) {
                return;
            }
            VisualisationService.verifyVisiblePerturbedObjects(date);
            this.currentDate = date;
        },
    },
    methods: {
        formatDate(newDate) {
            return moment.utc(newDate).format("YYYY-MM-DD");
        },
    },
    mounted() {
        this.$store.dispatch(CommonActions.setTool, 'ovt');
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.ovt);
    }
}
</script>

<style lang="scss">
@import "@/styles/common/visualisation.scss";
@import "@/styles/ovt/general.scss";
</style>