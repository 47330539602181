<template>
    <div class="sovt__timeline" :class="{ 'with-config': configPanelOpen }">
        <div class="sovt__timeline-buttons">
            <button
                class="circle default"
                @click="goToStart()"
                :disabled="isMinDate"
                :class="{ 'timeline-btn--disabled': isMinDate }"
            >
                <i class="icon-skip-back" />
            </button>
            <button
                class="circle primary reverse"
                @click="togglePlay(true)"
                :disabled="isMinDate"
                :class="{ 'timeline-btn--disabled': isMinDate }"
            >
                <i v-if="running && speed < 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button
                class="circle primary"
                @click="togglePlay(false)"
                :disabled="isMaxDate"
                :class="{ 'timeline-btn--disabled': isMaxDate }"
            >
                <i v-if="running && speed > 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button
                class="circle default"
                @click="goToEnd()"
                :disabled="isMaxDate"
                :class="{ 'timeline-btn--disabled': isMaxDate }"
            >
                <i class="icon-skip-forward" />
            </button>
        </div>

        <div class="sovt__timeline-slider" :class="{ 'sovt__timeline-slider--disabled': isOneDayPeriod }">
            <div class="sovt__timeline-inputs">
                <div class="input start-date">
                    <Tooltip position="top" :tooltipText="loading ? $keys.t.calculationsInProgress : ''">
                        <span class="input-label" :class="{ 'input-label--disabled': loading }"
                            >{{ $t.startDate }}:</span
                        >
                        <VueDatePicker
                            :model-value="dateFrom"
                            :min-date="'1950-01-01'"
                            :max-date="dateTo"
                            :enable-time-picker="false"
                            :format="dateFormat"
                            :clearable="false"
                            :year-range="[1950, dateToToYear()]"
                            auto-apply
                            @update:model-value="dateFromUpdate"
                            :disabled="loading"
                        />
                    </Tooltip>
                </div>
            </div>
            <Slider
                v-if="from && to"
                :tooltips="false"
                v-model="now"
                :min="from.getTime()"
                :max="to.getTime()"
                :step="1000 * 60 * 10"
                @slide="sliderNowUpdate($event)"
                :disabled="isOneDayPeriod"
            />
        </div>

        <div class="sovt__timeline-inputs">
            <div class="input end-date">
                <Tooltip position="top" :tooltipText="loading ? $keys.t.calculationsInProgress : ''">
                    <span class="input-label" :class="{ 'input-label--disabled': loading }">{{ $t.endDate }}:</span>
                    <VueDatePicker
                        :model-value="dateTo"
                        :min-date="dateFrom"
                        :max-date="dateToMax()"
                        :enable-time-picker="false"
                        :format="dateFormat"
                        :clearable="false"
                        :year-range="[dateFromToYear(), dateToMaxToYear()]"
                        auto-apply
                        @update:model-value="dateToUpdate"
                        :disabled="loading"
                    />
                </Tooltip>
            </div>
            <div class="input current-date">
                <span class="input-label" :class="{ 'input-label--disabled': isOneDayPeriod }"
                    >{{ $t.currentDate }}:</span
                >
                <VueDatePicker
                    :model-value="dateNow"
                    :min-date="dateFrom"
                    :max-date="dateTo"
                    :enable-time-picker="false"
                    :format="dateFormat"
                    :clearable="false"
                    :year-range="[dateFromToYear(), dateToToYear()]"
                    auto-apply
                    @update:model-value="currentDateUpdate"
                    :disabled="isOneDayPeriod"
                />
            </div>
            <div class="input">
                <span class="input-label">{{ $t.playbackSpeed }}:</span>
                <select @change="setSpeed($event.target.value)" :value="speed">
                    <option v-for="option of options" :key="option.value" :value="option.value" :hidden="option.hidden">
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "@vueform/slider";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment";
import Tooltip from "@/components/common/Tooltip";
import SpacekitService from "@/services/spacekit.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import TimeFrameService from "@/services/timeframe.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitTimeline",
    components: {
        VueDatePicker,
        Slider,
        Tooltip,
    },
    data() {
        return {
            now: new Date(),
            running: false,
            from: null,
            to: null,
            dateFrom: "",
            dateTo: "",
            dateNow: "",
            options: [
                { value: "365", label: "1 year / second" },
                { value: "30", label: "1 month / second" },
                { value: "7", label: "1 week / second" },
                { value: "1", label: "1 day / second" },
                { value: "-1", label: "1 day / second", hidden: "true" },
                { value: "-7", label: "1 week / second", hidden: "true" },
                { value: "-30", label: "1 month / second", hidden: "true" },
                { value: "-365", label: "1 year / second", hidden: "true" },
            ],
            speed: null,
        };
    },
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },

        simulationNow() {
            return VisualisationService.getNow();
        },

        timeframe() {
            return TimeFrameService.getTimeFrame();
        },

        isMinDate() {
            return this.dateNow === this.dateFrom;
        },

        isMaxDate() {
            return this.dateNow === this.dateTo;
        },

        isOneDayPeriod() {
            return this.dateTo === this.dateFrom;
        },

        selectedTimeframe() {
            return TimeFrameService.getTimeFrame();
        },

        simulationTime() {
            return TimeFrameService.getSimulationTime();
        },

        timelineSpeed() {
            return SynodicCalculationService.getSynodicTimelineSpeed();
        },

        loading() {
            return !!VisualisationService.loadingObjectsEphemerides().length;
        },
    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD/MM/YYYY");
        },

        dateToMax() {
            const max = UtilsService.createDateYearsFromNow(100);
            return UtilsService.dateToStringTechnical(max);
        },

        dateToMaxToYear() {
            const dateToMax = new Date(this.dateToMax());
            const year = dateToMax.getFullYear();
            return year;
        },

        dateFromToYear() {
            const dateFrom = new Date(this.dateFrom);
            const year = dateFrom.getFullYear();
            return year;
        },

        dateToToYear() {
            const dateTo = new Date(this.to);
            const year = dateTo.getFullYear();
            return year;
        },

        togglePlay(reverse) {
            if ((reverse && this.isMinDate) || (!reverse && this.isMaxDate)) {
                return;
            }
            this.running = !this.running;

            if (reverse && this.speed > 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (!reverse && this.speed < 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (this.running) {
                SpacekitService.play();
            } else {
                SpacekitService.pause();
            }
        },

        goToStart() {
            if (this.isMinDate) {
                return;
            }
            this.now = this.from.getTime();
        },

        goToEnd() {
            if (this.isMaxDate) {
                return;
            }
            this.now = this.to.getTime();
        },

        dateToUpdate(date) {
            this.dateTo = UtilsService.dateToStringTechnical(date);
        },

        dateFromUpdate(date) {
            this.dateFrom = UtilsService.dateToStringTechnical(date);
        },

        currentDateUpdate(date) {
            const newDate = new Date(date);
            this.now = newDate;
        },

        sliderNowUpdate(event) {
            const newDate = new Date(event);
            SpacekitService.setDate(newDate);
            this.dateNow = UtilsService.dateToStringTechnical(newDate);
        },

        setSpeed(value = null) {
            if (value) {
                if (this.speed < 0) {
                    this.speed = value * -1;
                } else {
                    this.speed = value;
                }
            }
            SpacekitService.setSpeed(this.speed);
            SynodicCalculationService.setSynodicTimelineSpeed(this.speed);
        },

        setSimulationTime() {
            const simulationTime = TimeFrameService.getSimulationTime();
            if (!simulationTime) {
                return;
            }
            const currentDateString = this.dateToString(simulationTime);
            this.now = moment(currentDateString, "YYYYMMDD").valueOf();
        },

        dateToString(date) {
            return moment.utc(date).format("YYYY-MM-DD");
        },
    },
    watch: {
        now(newVal) {
            const newDate = new Date(newVal);
            SpacekitService.setDate(newDate);
            this.dateNow = UtilsService.dateToStringTechnical(newDate);
        },

        dateFrom(newVal) {
            const d = new Date(newVal);
            if (newVal !== "" && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() >= new Date(this.to).getTime() ? new Date(this.to.getTime()) : d;
                if (d > new Date(this.now)) {
                    VisualisationService.setSimulationTime(new Date(newVal));
                    this.now = date;
                }
                this.from = date;
                TimeFrameService.setTimeFrame(this.dateFrom, this.dateTo);
            }
        },

        dateTo(newVal) {
            const d = new Date(newVal);
            if (newVal !== "" && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() <= new Date(this.from).getTime() ? new Date(this.from.getTime()) : d;
                if (d < new Date(this.now)) {
                    VisualisationService.setSimulationTime(new Date(newVal));
                    this.now = date;
                }
                this.to = date;
                TimeFrameService.setTimeFrame(this.dateFrom, this.dateTo);
            }
        },

        simulationNow(newVal) {
            if (this.running) {
                if ((newVal >= this.to && this.speed > 0) || (newVal <= this.from && this.speed < 0)) {
                    SpacekitService.pause();
                    this.running = false;
                    this.now = newVal.getTime() >= this.to.getTime() ? new Date(this.dateTo) : new Date(this.dateFrom);
                } else {
                    this.now = newVal;
                }
            }
            this.dateNow = UtilsService.dateToStringTechnical(newVal);
        },

        selectedTimeframe(timeframe) {
            if (
                (this.dateFrom !== timeframe.start && timeframe.start) ||
                (this.dateTo !== timeframe.end && timeframe.end) ||
                this.dateToString(this.simulationTime) !== this.dateToString(this.now)
            ) {
                this.setSimulationTime();
                this.dateFrom = timeframe.start;
                this.dateTo = timeframe.end;
            }
        },

        timelineSpeed(newSpeed) {
            if (this.speed === newSpeed) {
                return;
            }
            this.setSpeed(newSpeed);
        },
    },
    mounted() {
        if (this.timeframe.start && this.timeframe.end) {
            this.dateFrom = this.timeframe.start;
            this.dateTo = this.timeframe.end;
            this.from = new Date(this.dateFrom);
            this.to = new Date(this.dateTo);
            this.setSimulationTime();
        } else {
            const year = this.now.getFullYear();
            let month = this.now.getMonth() + 1 + "";
            let day = this.now.getDate() + "";
            const spread = 10;
            if (month.length === 1) {
                month = "0" + month;
            }
            if (day.length === 1) {
                day = "0" + day;
            }
            this.dateFrom = UtilsService.dateToStringTechnical(new Date(`${year - spread}-${month}-${day}`));
            this.dateTo = UtilsService.dateToStringTechnical(new Date(`${year + spread}-${month}-${day}`));

            this.from = new Date(this.dateFrom);
            this.to = new Date(this.dateTo);
            this.now = new Date(`${year}-${month}-${day}`);
        }
        this.speed = SynodicCalculationService.getSynodicTimelineSpeed() || 7;
        SpacekitService.setDate(this.now);
        SpacekitService.setSpeed(this.speed);
        this.dateNow = UtilsService.dateToStringTechnical(this.now);
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/timeline.scss";
</style>
