import { Text, preloadFont } from 'troika-three-text'
import * as THREE from 'three'
import moment from "moment";
import Config from '@/constants/config'
import UtilsService from '@/services/utils.service'
import SpacekitService from '@/services/spacekit.service'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { InvertColorsShader } from '@/shaders/InvertColorsShader'
import SynodicCalculationService from "@/services/synodic-calculation.service";
import VisualisationService from '@/services/visualisation.service'
import SkychartService from '@/services/skychart.service'

const HUDService = {
    initOrthographicInterface() {
        const vt = window['vt'];
      //  if (vt){
            const container = vt.getSimulationElement();
            const halfWidth = container.clientWidth / 2;
            const halfHeight = container.clientHeight / 2;
            vt.sceneOrtho = new THREE.Scene();
            vt.cameraOrtho = new THREE.OrthographicCamera( halfWidth * -1, halfWidth, halfHeight, halfHeight * -1, -1000, 2000 );
            HUDService.buildOrthographicInterface(vt.sceneOrtho);
      //  }
    },

    initWatermarks(scene) {
        const watermarks = new THREE.Group();
        watermarks.name = 'Watermarks';
        watermarks.visible = false;
        
        const map = new THREE.TextureLoader().load('/static/images/ESA_Logo_white.svg');
        map.minFilter = THREE.LinearFilter;
        const material = new THREE.SpriteMaterial({ map: map, opacity: 0.25 });
        const logo = new THREE.Sprite(material);
        logo.name = 'EsaLogo';
        logo.scale.set(66, 24, 1)

        const year = (new Date()).getFullYear();
        const attribution = new Text();
        attribution.fontSize = 10;
        attribution.text = year + ' ' + Config.attributionText;
        attribution.name = 'AttributionText';
        attribution.material.opacity = 0.5;
        
        watermarks.add(logo);
        watermarks.add(attribution);

        scene.add(watermarks)
    },

    updateWatermarks(scene, container) {
        if (scene && container){
            const halfWidth = container.clientWidth / 2;
            const halfHeight = container.clientHeight / 2;
            const size = new THREE.Vector3();

            const logo = scene.getObjectByName('EsaLogo');
            const tool = VisualisationService.getTool();
            const skychartDetailsHidden = SkychartService.getObjectsDetailsHide(); 
            const offset = tool === 'scd' && !skychartDetailsHidden ? 400 : 0;
            logo.position.set(-halfWidth + 33 + 15 + offset, halfHeight - 12 - 15, 0);
            
            const attribution = scene.getObjectByName('AttributionText');
            attribution.geometry.boundingBox.getSize(size);
            attribution.position.set(halfWidth - size.x - 15, - halfHeight + size.y + 15, 0);
            attribution.sync();
        }
    },

    toggleWatermarks(boolean) {
        const scene = window['vt'] ? window['vt'].sceneOrtho : window['scd'].sceneOrtho;
        const watermarks = scene.getObjectByName('Watermarks');
        if (watermarks) {
            watermarks.visible = boolean;
        }
    },

    async buildOrthographicInterface(scene) {
        await preloadFont({ font: '/static/fonts/NotesEsaReg.otf' }, () => {});
        HUDService.initWatermarks(scene);
        HUDService.initBoxes(scene);
    },

    initBoxes(scene) {
        const boxes = new THREE.Group();
        boxes.name = 'Boxes';
        boxes.visible = false;

        const orbitLegendBox = HUDService.createBox({
            width: 209, height: 96, name: 'OrbitLegend', title: 'ORBIT LEGEND', left: 15, bottom: 14
        }, HUDService.createBoxContent('OrbitLegend'));
        boxes.add(orbitLegendBox);

        const arrowLegendBox = HUDService.createBox({
            width: 172, height: 120, name: 'ArrowLegend', title: 'ARROW LEGEND', left: 15, bottom: 15
        }, HUDService.createBoxContent('ArrowLegend'));
        boxes.add(arrowLegendBox);

        const objectDetailsBox = HUDService.createBox({
            width: 270, height: 232, name: 'ObjectDetails', title: 'OBJECT DETAILS', left: 15, bottom: 15
        }, HUDService.createBoxContent('ObjectDetails'));
        boxes.add(objectDetailsBox);

        scene.add(boxes);
    },

    createBox(config, content) {
        const geometry = new THREE.PlaneGeometry( config.width, config.height );
        const box = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial({ color: 0x161F27, transparent: true, opacity: 0 }));
        box.config = config;
        box.name = config.name;

        const background = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { color: 0x161F27, transparent: true, opacity: 0.9 } ) );
        box.background = background;
        box.add(background);
        
        const header = HUDService.createText(config.title);
        header.position.set(-config.width/2 + 20, config.height/2 - 12, 1);
        box.add(header);

        const headerLine = HUDService.createLine(0x262F3A, config.width - 30, 1);
        headerLine.position.set(0, config.height/2 - 36, 1);
        box.add(headerLine);

        box.add(content);
        box.content = content;
        box.geometry.computeBoundingBox();
        return box;
    },

    createText(textContent) {
        const text = new Text();
        text.font = '/static/fonts/NotesEsaReg.otf';
        text.text = textContent;
        text.fontSize = 14;
        text.color = 0xFFFFFF;
        text.lineHeight = 1.5;
        return text;
    },

    createLine(color, width, height) {
        const geometry = new THREE.PlaneGeometry( width, height );
        const line = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { color } ) );
        return line;
    },

    createBoxContent(type) {
        const tool = VisualisationService.getTool();
        const group = new THREE.Group();

        if (type === 'OrbitLegend' && tool !== 'sovt') {
            const keplerianColor = VisualisationService.getSettings().objectsSettings.keplerianOrbits.color;
            const keplerianLine = HUDService.createLine(UtilsService.hexColor(keplerianColor), 50, 2);
            keplerianLine.position.set(-27, 1 - 4, 1);
            group.add(keplerianLine);

            const keplerianLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.keplerianOrbits.color,0.4);
            const keplerianLightLine = HUDService.createLine(UtilsService.hexColor(keplerianLightColor), 50, 2);
            keplerianLightLine.position.set(-27, 1 - 9, 1);
            group.add(keplerianLightLine);

            const perturbedColor = VisualisationService.getSettings().objectsSettings.perturbedOrbits.color;
            const perturbedLine = HUDService.createLine(UtilsService.hexColor(perturbedColor), 50, 2);
            perturbedLine.position.set(-27, 1 - 26, 1);
            group.add(perturbedLine);

            const perturbedLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.perturbedOrbits.color,0.4);
            const perturbedLightLine = HUDService.createLine(UtilsService.hexColor(perturbedLightColor), 50, 2);
            perturbedLightLine.position.set(-27, 1 - 31, 1);
            group.add(perturbedLightLine);
        
            const text = HUDService.createText(`
                Keplerian orbits
                Perturbed orbits
            `);
            text.position.set(-60, 24, 1);
            group.add(text);
        }

        if (type === 'OrbitLegend' && tool === 'sovt') {
            const keplerianColor = VisualisationService.getSettings().objectsSettings.keplerianOrbits.color;
            const keplerianLine = HUDService.createLine(UtilsService.hexColor(keplerianColor), 50, 2);
            keplerianLine.position.set(-27, 1 - 4, 1);
            group.add(keplerianLine);

            const keplerianLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.keplerianOrbits.color, 0.4);
            const keplerianLightLine = HUDService.createLine(UtilsService.hexColor(keplerianLightColor), 50, 2);
            keplerianLightLine.position.set(-27, 1 - 9, 1);
            group.add(keplerianLightLine);

            const perturbedColor = VisualisationService.getSettings().objectsSettings.perturbedOrbits.color;
            const perturbedLine = HUDService.createLine(UtilsService.hexColor(perturbedColor), 50, 2);
            perturbedLine.position.set(-27, 1 - 26, 1);
            group.add(perturbedLine);

            const perturbedLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.perturbedOrbits.color, 0.4);
            const perturbedLightLine = HUDService.createLine(UtilsService.hexColor(perturbedLightColor), 50, 2);
            perturbedLightLine.position.set(-27, 1 - 31, 1);
            group.add(perturbedLightLine);
        
            const text = HUDService.createText(`
                Keplerian orbits
                Perturbed orbits
            `);
            text.position.set(-60, 24, 1);
            group.add(text);
        }

        if (type === 'ArrowLegend') {            
            const sunColor = VisualisationService.getSettings().objectsSettings.sunOrbit.color;
            const moonColor = VisualisationService.getSettings().objectsSettings.moonOrbit.color;
            const asteroidColor = VisualisationService.getSettings().objectsSettings.asteroidOrbit.color;
            const sunLine = HUDService.createLine(UtilsService.hexColor(sunColor), 50, 2);
            sunLine.position.set(-25, 1 - 10, 1);
            group.add(sunLine);
            
            const moonLine = HUDService.createLine(UtilsService.hexColor(moonColor), 50, 2);                        
            moonLine.position.set(-25, 1 - 32, 1);
            group.add(moonLine);

            const objectLine = HUDService.createLine(UtilsService.hexColor(asteroidColor), 50, 2);
            objectLine.position.set(-25, 1 - 54, 1);
            group.add(objectLine);
        
            const text = HUDService.createText(`
                Sun vector
                Moon vector
                Object vector
            `);
            text.position.set(-55, 23, 1);
            group.add(text);
        }

        if (type === 'ObjectDetails') {
            const labels = HUDService.createText('');
            labels.lineHeight = 1.6;
            labels.position.set(-135 + 20, 116 - 41, 1);
            group.add(labels);

            const values = HUDService.createText('');
            values.lineHeight = 1.6;
            values.position.set(-135 + 165, 116 - 41, 1);
            group.add(values);
        }
        
        return group;
    },

    updateBoxes(scene, container) {
        if (scene && container) {
            const onAir = VisualisationService.getOrbitRecorderOnAir();
            const boxes = scene.getObjectByName('Boxes');
            if (onAir && boxes) {
                boxes.visible = true;
            } else {
                boxes.visible = false;
                return
            }

            const tool = VisualisationService.getTool();
            const halfWidth = container.clientWidth / 2;
            const halfHeight = container.clientHeight / 2;
            const size = new THREE.Vector3();
            const activeObject = VisualisationService.getOrbitActiveObject();
            const selectedCalculatedSynodicObject = tool === 'sovt' ? SynodicCalculationService.getSelectedCalculatedObject() : null;
            const activeSynodicObject = tool === 'sovt' ? SynodicCalculationService.getSynodicActiveObject() : null;
            const settings = VisualisationService.getSettings().objectsSettings;
            const orbitLegendVisible = settings.orbitLegend.value && (tool === 'ovt' || tool === 'sovt');
            const arrowLegendVisible = settings.arrowLegend.value && tool === 'fvt';
            const objectDetailsVisible = (tool !== 'sovt' && settings.objectDetails.value && activeObject) || (tool === 'sovt' && settings.objectDetails.value && selectedCalculatedSynodicObject && activeSynodicObject);
       
            const orbitLegendBox = scene.getObjectByName('OrbitLegend');
            if (orbitLegendBox) {
                if (orbitLegendVisible) {
                    orbitLegendBox.visible = true;
                    const config = orbitLegendBox.config;
                    orbitLegendBox.content.position.set(-32, 3, 1);
                    orbitLegendBox.geometry.boundingBox.getSize(size);

                    if (tool === 'sovt') {
                        const keplerianColor = VisualisationService.getSettings().objectsSettings.keplerianOrbits.color;
                        orbitLegendBox.content.children[0].material.color.set(UtilsService.hexColor(keplerianColor));

                        const keplerianLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.keplerianOrbits.color, 0.4);
                        orbitLegendBox.content.children[1].material.color.set(UtilsService.hexColor(keplerianLightColor));

                        const perturbedColor = VisualisationService.getSettings().objectsSettings.perturbedOrbits.color;
                        orbitLegendBox.content.children[2].material.color.set(UtilsService.hexColor(perturbedColor));

                        const perturbedLightColor = UtilsService.applyOpacityToHexColor(VisualisationService.getSettings().objectsSettings.perturbedOrbits.color, 0.4);
                        orbitLegendBox.content.children[3].material.color.set(UtilsService.hexColor(perturbedLightColor));
                    }
                    
                    let objectDetailsOffset = 0;
                    if (objectDetailsVisible) {
                        if (activeObject && activeObject._useEphemTable) {
                            objectDetailsOffset = 265;
                        } else if (tool === 'sovt' && activeSynodicObject) {
                            objectDetailsOffset = 245 + 128;
                        } else {
                            objectDetailsOffset = 245;
                        }
                    }
                    orbitLegendBox.position.set(- halfWidth + size.x/2 + config.left, - halfHeight + size.y/2 + config.bottom + objectDetailsOffset, 1);
                } else {
                    orbitLegendBox.visible = false;
                }
            }

            const arrowLegendBox = scene.getObjectByName('ArrowLegend');
            if (arrowLegendBox) {
                if (arrowLegendVisible) {
                    arrowLegendBox.visible = true;
                    const config = arrowLegendBox.config;
                    arrowLegendBox.content.position.set(-17, 13, 1);
                    arrowLegendBox.geometry.boundingBox.getSize(size);
                    
                    let objectDetailsOffset = 0;
                    if (objectDetailsVisible) {
                        objectDetailsOffset = 245;
                    }
                    arrowLegendBox.position.set(- halfWidth + size.x/2 + config.left, - halfHeight + size.y/2 + config.bottom + objectDetailsOffset, 1);
                } else {
                    arrowLegendBox.visible = false;
                }
            }

            const objectDetailsBox = scene.getObjectByName('ObjectDetails');
            if (objectDetailsBox) {
                if (objectDetailsVisible) {
                    objectDetailsBox.visible = true;
                    
                    let orbitReliableOffset = 0;
                    if (activeObject && activeObject._useEphemTable && tool === 'ovt') {
                        orbitReliableOffset = 20;
                    }

                    if (activeSynodicObject && tool === 'sovt') {
                        orbitReliableOffset = 128;
                    }

                    const config = objectDetailsBox.config;
                    const contentBoundingBox = new THREE.Box3().setFromObject(objectDetailsBox.content);
                    const contentSize = contentBoundingBox.getSize(new THREE.Vector3());
                    const contentWidth = contentSize.x + 40;

                    objectDetailsBox.background.scale.x = 1 + ((contentWidth - config.width) / config.width);
                    objectDetailsBox.background.scale.y = 1 + (orbitReliableOffset / config.height);
                    objectDetailsBox.background.position.set((contentWidth - config.width)/2, -orbitReliableOffset/2, 0);
                    objectDetailsBox.geometry.boundingBox.getSize(size);
                    objectDetailsBox.position.set(- halfWidth + size.x/2 + config.left, - halfHeight + size.y/2 + config.bottom + orbitReliableOffset, 1);
                    
                    if (tool !== 'sovt') {
                        objectDetailsBox.content.children[0].text = `
                            Designator:
                            Diameter:
                            Absolute magnitude:
                            Simulation time MJD:
                            Simulation time:
                            Distance to Sun:
                            Distance to Earth center:
                            `.trim().replace(/^ {28}/gm, '');
                    }

                    if (tool === 'sovt') {
                        objectDetailsBox.content.children[0].text = `
                        Designator:
                        Diameter:
                        Absolute magnitude:
                        Slope:
                        Orbit period:
                        Synodic orbit period:
                        Simulation time MJD:
                        Simulation time:
                        Distance to Sun:
                        Distance to Earth center:
                        Elongation angle:
                        Phase angle:
                        Visual magnitude:
                        Orbit propagation
                        `.trim().replace(/^ {24}/gm, '');
                    }

                    if (tool === 'ovt') {
                        objectDetailsBox.content.children[0].text += `
                            Orbit propagation:`.replace(/^ {28}/gm, '');
                        if (activeObject && activeObject._useEphemTable) {
                            objectDetailsBox.content.children[0].text += `
                                Orbit reliable:`.replace(/^ {32}/gm, '');
                        }
                    } else if (tool === 'fvt') {
                        objectDetailsBox.content.children[0].text += `
                            Model source:`.replace(/^ {28}/gm, '');
                    }
                    objectDetailsBox.content.children[0].sync(); 

                    if (tool !== 'sovt') {
                            objectDetailsBox.content.children[1].text = `
                            ${activeObject._options.name}
                            ${activeObject._options.diam ? activeObject._options.diam.toFixed(1) : '-'} m
                            ${activeObject._options.mag ? activeObject._options.mag.toFixed(1) : '-'} mag
                            ${tool === 'ovt' ? parseInt(UtilsService.dateToJulian(activeObject.distance.date)) - 2400000 : (UtilsService.dateToJulian(activeObject.distance.date) - 2400000.5).toFixed(5)}
                            ${tool === 'ovt' ? UtilsService.dateToString(activeObject.distance.date) : UtilsService.dateToString(activeObject.distance.date, true)}
                            ${activeObject.distance.sun} au
                            ${activeObject.distance.earth} au
                            `.trim().replace(/^ {28}/gm, '');
                    }

                    if (tool === 'sovt') {
                        const selectedCalculatedSynodicObject = SynodicCalculationService.getSelectedCalculatedObject();
                        const synodicTime = {
                            jd: parseInt(UtilsService.dateToJulian(VisualisationService.getSimulationTime()) - 2400000.5),
                            date: moment(UtilsService.dateToStringTechnical(VisualisationService.getSimulationTime())).format("DD-MM-YYYY"),
                        };
                        const synodicDistances = SynodicCalculationService.calculateObjectDistances(activeSynodicObject);
                        const slopeValue = activeSynodicObject && selectedCalculatedSynodicObject ? selectedCalculatedSynodicObject.objectData.g.toFixed(2) : '-';
                        const orbitPeriod = SynodicCalculationService.calculateOrbitPeriod();
                        const synodicOrbitPeriod = SynodicCalculationService.calculateSynodicOrbitPeriod();
                        const elongationAngle = SynodicCalculationService.calculateElongationAngle();
                        const phaseAngle = SynodicCalculationService.getCurrentChartValue('phaseAngle');
                        const visualMagnitude = SynodicCalculationService.getCurrentChartValue('apparentMagOriginalValues');
                        const orbitPropagation = activeSynodicObject._id.endsWith(
                            SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED
                        ) ? 'Accurate' : 'Keplerian';

                        objectDetailsBox.content.children[1].text = `
                        ${activeSynodicObject._options.name}
                        ${activeSynodicObject._options.diam ? activeSynodicObject._options.diam.toFixed(1) : '-'} ${activeSynodicObject._options.diam ? 'm' : ''}
                        ${activeSynodicObject._options.mag ? activeSynodicObject._options.mag.toFixed(1) : '-'} mag
                        ${slopeValue}
                        ${orbitPeriod !== null ? orbitPeriod.toFixed(3) : '-'} ${orbitPeriod !== null ? 'yr' : ''}
                        ${synodicOrbitPeriod !== null ? synodicOrbitPeriod.toFixed(3) : '-'} ${synodicOrbitPeriod !== null ? 'yr' : ''}
                        ${synodicTime.jd}
                        ${synodicTime.date}
                        ${phaseAngle !== null ? synodicDistances.center : '-'} ${phaseAngle !== null ? 'au' : ''}
                        ${phaseAngle !== null ? synodicDistances.earth : '-'} ${phaseAngle !== null ? 'au' : ''}
                        ${elongationAngle !== null && phaseAngle !== null ? elongationAngle.toFixed(1) : '-'} ${elongationAngle !== null && phaseAngle !== null ? 'deg' : ''}
                        ${phaseAngle !== null ? phaseAngle.toFixed(1) : '-'} ${phaseAngle !== null ? 'deg' : ''}
                        ${visualMagnitude !== null ? visualMagnitude.toFixed(1) : '-'} ${visualMagnitude !== null ? 'mag' : ''}
                        ${orbitPropagation}
                        `.trim().replace(/^ {24}/gm, '');
                    }
                
                    if (tool === 'ovt') {
                        objectDetailsBox.content.children[1].text += `
                            ${activeObject._useEphemTable ? 'Accurate' : 'Keplerian'}`.replace(/^ {28}/gm, '');
                        if (activeObject._useEphemTable && tool === 'ovt') {
                            objectDetailsBox.content.children[1].text += `
                                ${HUDService.isOrbitReliable(activeObject)}`.replace(/^ {32}/gm, '');
                        }
                    } else if (tool === 'fvt') {
                        objectDetailsBox.content.children[1].text += `
                            ${activeObject._options.shape.modelSource}`.replace(/^ {28}/gm, '');
                    }
                    objectDetailsBox.content.children[1].sync();
                } else {
                    objectDetailsBox.visible = false;
                }
            }
        }
    },

    isOrbitReliable(activeObject) {
        const tool = VisualisationService.getTool();
        if (tool !== 'ovt') return false;
        const mjd = parseInt(UtilsService.dateToJulian(activeObject.distance.date)) - 2400000;
        return activeObject._options.uncTable.indexOf(mjd) > -1 ? 'No' : 'Yes';
    },

    createLabel(spaceObject,  color=null, fontSize=null, anchors = null, options = null) {
        if (!spaceObject || !spaceObject._options || !spaceObject._options.name) return;
        HUDService.removeLabel(spaceObject);
        const vt = window['vt'];
        const name = spaceObject._options.name;
        const labelColor = VisualisationService.getSettings().objectsSettings.objectNames.color;
        const labelFontSize = VisualisationService.getSettings().objectsSettings.objectNamesSize.value;
        const labelarray = [];
        const label = new Text();
        label.text = name;
        label.name = name;        
        label.fontSize = fontSize ? fontSize : labelFontSize;                
        label.color = color ? color : labelColor;
        label.anchorX = anchors ? anchors[0] : 'center';
        label.anchorY = anchors ? anchors[1] : 'bottom';
        label.outlineWidth = 1;
        label.outlineBlur = 1;
        label.layers.set(spaceObject._options.labelLayer || 0);
        label.options = options;
        labelarray.push(label);
        spaceObject._label3d = labelarray;
        vt.sceneOrtho.add(label);
    },

    removeLabel(spaceObject) {
        const vt = window['vt'];
        const name = spaceObject._options.name;

        const label = vt.sceneOrtho.getObjectByName(name)
        if (label) {
            vt.sceneOrtho.remove(label);
        }
    },
    
    updateLabelVisibility(name,boolean) {
        const vt = window['vt'];
        const spaceObjects = vt.spaceObjects.concat(vt.planets, vt.flybyScene);
        for (const spaceObject of spaceObjects) {
            if (spaceObject.name===name){                
                spaceObject._label3d[0].visible = boolean;
                break;
            }
        }       
    },    

    updateLabelsColor(name, color) {
        const vt = window['vt'];
        let spaceObjects;
        if (name === 'objects') {
            spaceObjects = vt.spaceObjects.concat(vt.flybyScene);            
            for (const spaceObject of spaceObjects) {
                if (!spaceObject._label3d) continue;
                spaceObject._label3d[0].color = color;    
            }     
        } else if (name === 'planets') {
            spaceObjects = vt.spaceObjects.concat(vt.planets);
            const planets = vt.planets.filter(e => e._id !== 'earth');                
            for (const planet of planets) {
                if (!planet._label3d) continue;
                planet._label3d[0].color = color;    
            }              
        } else if (name === 'earth') {
            const earth = vt.planets.filter(e => e._id === 'earth' || e._id === SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME)[0];                
            if (earth && earth._label3d) {
                earth._label3d[0].color = color;    
            }                        
        }                
    },

    updateLabelsSize(name, size) {
        const vt = window['vt'];
        let spaceObjects;
        if (name === 'objects') {
            spaceObjects = vt.spaceObjects.concat(vt.flybyScene);            
            for (const spaceObject of spaceObjects) {
                if (!spaceObject._label3d) continue;
                spaceObject._label3d[0].fontSize = size;
            }     
        } else if (name === 'planets') {
            spaceObjects = vt.spaceObjects.concat(vt.planets);
            const planets = vt.planets.filter(e => e._id !== 'earth');                
            for (const planet of planets) {
                if (!planet._label3d) continue;
                planet._label3d[0].fontSize = size;
            }              
        } else if (name === 'earth') {
            const earth = vt.planets.filter(e => e._id === 'earth' || e._id === SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME)[0];                
            if (earth && earth._label3d) {
                earth._label3d[0].fontSize = size;
            }                        
        }        
             
    },

    updateLabelsPosition() {
        const vt = window['vt'];
        const camera = vt.getViewer().camera;
        const container = vt.getSimulationElement();
        const halfWidth = container.clientWidth / 2;
        const halfHeight = container.clientHeight / 2;
        const labelOffsetTop = 5;
        const spaceObjects = vt.spaceObjects.concat(vt.planets, vt.flybyScene);
        const closestApproachPointVisible = VisualisationService.getSettings().objectsSettings.closestApproachPoint;
        for (const spaceObject of spaceObjects) {
            if (spaceObject && !spaceObject._label3d) continue;
            
            const position = spaceObject.position || spaceObject.get3jsObjects()[0].position;

            for  (let i = 0; i < spaceObject._label3d.length; i++){
                if (SpacekitService.checkCameraFrustum(camera, position)) {
                    const vector = new THREE.Vector3().copy(position).project(camera);
                    const x = UtilsService.normalizeAlt(vector.x, -1, 1) * halfWidth;
                    let y = UtilsService.normalizeAlt(vector.y, -1, 1) * halfHeight;

                    y = spaceObject._label3d[i]._private_anchorY === 'bottom' ? y + labelOffsetTop : y - labelOffsetTop;
                    const hideOnStop = spaceObject._label3d[i] && spaceObject._label3d[i].options ? spaceObject._label3d[i].options.hideOnStop : false;

                    if (i!==1){
                        if (spaceObject.isStopped && hideOnStop) {
                            spaceObject._label3d[i].visible = false;
                        } else {
                            spaceObject._label3d[i].visible = true;
                        }
                    }
        
                    spaceObject._label3d[i].position.set(x, y, 0);

                    if (spaceObject.name === 'ClosestApproachPoint') {
                        if (closestApproachPointVisible) {
                            spaceObject._label3d[i].visible = true;
                        } else {
                            spaceObject._label3d[i].visible = false;
                        }
                    }
                } else {
                    spaceObject._label3d[i].visible = false;
                }
                spaceObject._label3d[0].sync();
            }
        }
    },

    initInvertColorsShader() {
        const vt = window['vt'];
        const shaderPass = new ShaderPass( InvertColorsShader );
        const renderPassPerspective = new RenderPass(vt.getScene(), vt.getViewer().camera);
        const renderPassOrthographic = new RenderPass(vt.sceneOrtho, vt.cameraOrtho);
        renderPassOrthographic.clear = false;

        const composer = new EffectComposer(vt.getRenderer());
        composer.addPass(renderPassPerspective);
        composer.addPass(renderPassOrthographic);
        composer.addPass(shaderPass);
        vt.effectComposer = composer;
    },

    setAnimationLoop() {
        const vt = window['vt'];
        if (vt) {
            const animationLoop = () => {
                HUDService.updateLabelsPosition();
                HUDService.updateWatermarks(vt.sceneOrtho, vt.getSimulationElement());
                HUDService.updateBoxes(vt.sceneOrtho, vt.getSimulationElement());
                if (vt.sceneOrtho && vt.cameraOrtho){
                    vt.getRenderer().render(vt.sceneOrtho, vt.cameraOrtho);
                }

                const inverted = VisualisationService.getInvertColors();
                const onAir = VisualisationService.getOrbitRecorderOnAir();
                if (inverted && onAir) {
                    vt.effectComposer.render();
                }
            }
            vt.getRenderer().setAnimationLoop(animationLoop);
        }
    }
}

export default HUDService;
