<template>
    <div class="ovt__timeline" :class="{'with-config': configPanelOpen}">
        <div class="ovt__timeline-buttons">
            <button class="circle default" @click="goToStart()" :disabled="isMinDate" :class="{ 'timeline-btn--disabled': isMinDate }">
                <i class="icon-skip-back" />
            </button>
            <button class="circle primary reverse" @click="togglePlay(true)" :disabled="isMinDate" :class="{ 'timeline-btn--disabled': isMinDate }">
                <i v-if="running && speed < 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button class="circle primary" @click="togglePlay(false)" :disabled="isMaxDate" :class="{ 'timeline-btn--disabled': isMaxDate }">
                <i v-if="running && speed > 0" class="icon-pause" />
                <i v-else class="icon-play" />
            </button>
            <button class="circle default" @click="goToEnd()" :disabled="isMaxDate" :class="{ 'timeline-btn--disabled': isMaxDate }">
                <i class="icon-skip-forward" />
            </button>
        </div>

        <div class="ovt__timeline-slider">
            <div class="ovt__timeline-inputs">
                <div class="input start-date">
                    <span class="input-label">{{ $t.startDate }}:</span>
                    <!--<input type="date" v-model="dateFrom" :min="'1970-01-01'" :max="dateTo" />-->
                    <VueDatePicker v-model="dateFrom" :min-date="'1950-01-01'" :max-date="dateTo" :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" :year-range="[1950,dateToToYear()]" auto-apply @update:model-value="dateFromUpdate" text-input />
                </div>
            </div>
            <!--<Slider v-if="from && to" :tooltips="false" v-model="now" :min="from.getTime()" :max="to.getTime()" :step="1000*60*10" @update="sliderNowUpdate($event)" />-->
            <Slider v-if="from && to" :tooltips="false" v-model="now" :min="from.getTime()" :max="to.getTime()" :step="1000*60*10" @slide="sliderNowUpdate($event)" />
        </div>

        <div class="ovt__timeline-inputs">
            <div class="input end-date">
                <span class="input-label">{{ $t.endDate }}:</span>
                <!--<input type="date" v-model="dateTo" :min="dateFrom" :max="'2470-01-01'" />-->
                <VueDatePicker v-model="dateTo" :min-date="dateFrom" :max-date="dateToMax()" :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" :year-range="[dateFromToYear(),dateToMaxToYear()]" auto-apply @update:model-value="dateToUpdate" text-input />
            </div>
            <div class="input current-date">
                <span class="input-label">{{ $t.currentDate }}:</span>
                <!--<input type="date" v-model="dateNow" :min="dateFrom" :max="dateTo" @change="currentDateUpdate($event.target.value)" />-->
                <VueDatePicker :model-value="dateNow" :min-date="dateFrom" :max-date="dateTo" :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" :year-range="[dateFromToYear(),dateToToYear()]" auto-apply @update:model-value="currentDateUpdate" text-input />
            </div>
            <div class="input">
                <span class="input-label">{{ $t.playbackSpeed }}:</span>
                <select @change="setSpeed($event.target.value)" :value="speed">
                    <option v-for="option of options" :key="option.value" :value="option.value" :hidden="option.hidden">{{ option.label }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
//import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import VisualisationService from '@/services/visualisation.service'
import SpacekitService from '@/services/spacekit.service'
import UtilsService from '@/services/utils.service'
import TimeFrameService from '@/services/timeframe.service'
import Slider from '@vueform/slider'
import moment from 'moment'
import MemoryService from '@/services/memory.service'

export default {
    name: 'OrbitTimeline',
    components: {
        VueDatePicker,
        Slider,
    },
    data() {
        return {
            now: new Date(),
            running: false,
            from: null,
            to: null,
            dateFrom: '',
            dateTo: '',
            dateNow: '',
            options: [
                {value: '365', label: '1 year / second'},
                {value: '30', label: '1 month / second'},
                {value: '7', label: '1 week / second'},
                {value: '1', label: '1 day / second'},
                {value: '-1', label: '1 day / second', hidden: 'true'},
                {value: '-7', label: '1 week / second', hidden: 'true'},
                {value: '-30', label: '1 month / second', hidden: 'true'},
                {value: '-365', label: '1 year / second', hidden: 'true'},
            ],
            speed: 7
        }
    },
    computed: {
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        simulationNow() {
            return VisualisationService.getNow();
        },
        timeframe() {
            return TimeFrameService.getTimeFrame();
        },
        isMinDate() {
            return this.dateNow === this.dateFrom;
        },

        isMaxDate() {
            return this.dateNow === this.dateTo;
        },
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        dateToMax() {
            const currentDate = new Date();  // Get the current date
            const currentYear = currentDate.getFullYear();  // Get the current year
            const max = new Date(currentYear + 100, 11, 31);
            return UtilsService.dateToStringTechnical(max);
        },
        dateToMaxToYear() {
            const dateToMax = new Date(this.dateToMax());
            const year = dateToMax.getFullYear();
            return year;
        },
        dateFromToYear() {
            const dateFrom = new Date(this.dateFrom);
            const year = dateFrom.getFullYear();
            return year;
        },
        dateToToYear() {
            const dateTo = new Date(this.to);
            const year = dateTo.getFullYear();
            return year;
        },
        togglePlay(reverse) {
            this.running = !this.running;

            if (reverse && this.speed > 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (!reverse && this.speed < 0) {
                this.speed = this.speed * -1;
                this.setSpeed();
                this.running = true;
            }

            if (this.running) {
                SpacekitService.play();
            } else {
                SpacekitService.pause();
            }
        },
        goToStart() {
            this.now = this.from.getTime();
        },
        goToEnd() {
            this.now = this.to.getTime();
        },
        currentDateUpdate(event) {
            const newDate = new Date(event);
            VisualisationService.setCurrentDate(newDate);
            SpacekitService.setDate(newDate);
            this.now = newDate;
        },
        sliderNowUpdate(event) {
            const newDate = new Date(event);
            SpacekitService.setDate(newDate);
            this.dateNow = UtilsService.dateToStringTechnical(newDate);
            VisualisationService.setCurrentDate(new Date(this.dateNow));
            SpacekitService.setDate(new Date(this.dateNow));
        },
        setSpeed(value = null) {
            if (value) {
                if (this.speed < 0) {
                    this.speed = value * -1;
                } else {
                    this.speed = value;
                }
            }
            SpacekitService.setSpeed(this.speed);
            VisualisationService.setPlaybackSpeed(this.speed);
        },
        dateToUpdate(date) {
            this.dateTo = UtilsService.dateToStringTechnical(date);
        },
        dateFromUpdate(date) {
            this.dateFrom = UtilsService.dateToStringTechnical(date);
        },
    },
    watch: {
        now(newVal) {
            const newDate = new Date(newVal);
            SpacekitService.setDate(newDate);
            this.dateNow = UtilsService.dateToStringTechnical(newDate);
            VisualisationService.setCurrentDate(newDate);
            VisualisationService.setPreviousTimeOVT(newDate);
        },
        dateFrom(newVal) {
            const d = new Date(newVal);
            if (newVal !== '' && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() >= new Date(this.to).getTime() ? new Date(this.to.getTime() - (1000*60*60*24)) : d;
                if (d > new Date(this.now)) {
                    this.now = date;
                }
                this.from = date;
                TimeFrameService.setTimeFrame(this.dateFrom, this.dateTo);
            }
        },
        dateTo(newVal) {
            const d = new Date(newVal);
            if (newVal !== '' && d instanceof Date && !isNaN(d)) {
                const date = d.getTime() <= new Date(this.from).getTime() ? new Date(this.from.getTime() + (1000*60*60*24)) : d;
                if (d < new Date(this.now)) {
                    this.now = date;
                }
                this.to = date;
                TimeFrameService.setTimeFrame(this.dateFrom, this.dateTo);
            }
        },
        simulationNow(newVal) {
            if (this.running) {
                this.now = newVal;
                if ((newVal >= this.to && this.speed > 0) || (newVal <= this.from && this.speed < 0)) {
                    SpacekitService.pause();
                    this.running = false;
                }
            }
            this.dateNow = UtilsService.dateToStringTechnical(newVal);
            VisualisationService.setCurrentDate(newVal);
        },
    },
    mounted() {
        const previousSpeed = VisualisationService.getPlaybackSpeed();
        const prevStartDate = TimeFrameService.getTimeFrame() ? TimeFrameService.getTimeFrame().start : null;
        const prevEndDate = TimeFrameService.getTimeFrame() ? TimeFrameService.getTimeFrame().end : null;
        const previousDate = VisualisationService.getPreviousTimeOVT();
        const previousDateForm = new Date(previousDate);

        setTimeout(() => {
            if((previousDate) && (this.now !== previousDateForm)){
                this.now = previousDateForm;
                SpacekitService.setDate(previousDateForm);
                VisualisationService.setNow(previousDateForm);
                this.dateNow = UtilsService.dateToStringTechnical(previousDateForm, true);
            }
        }, 1000); 

        if ((previousSpeed) && (this.speed !== previousSpeed))
            this.speed = previousSpeed;

        if ((prevStartDate && prevStartDate!==this.dateFrom) || (prevEndDate && prevEndDate!==this.dateTo)){
            this.dateFrom = prevStartDate;
            this.dateTo = prevEndDate;
        }


        if (this.timeframe.start && this.timeframe.end) {
            this.dateFrom = this.timeframe.start;
            this.dateTo = this.timeframe.end;
            this.now = new Date(this.dateFrom);
            this.from = new Date(this.dateFrom);
            this.to = new Date(this.dateTo);
        } else {
            const year = this.now.getFullYear();
            let month = this.now.getMonth() + 1 + '';
            let day = this.now.getDate() + '';
            const spread = 10;
            
            if (month.length === 1) {
                month = '0' + month;
            }

            if (day.length === 1) {
                day = '0' + day;
            }

            this.dateFrom = UtilsService.dateToStringTechnical(new Date(`${year}-${month}-${day}`));
            this.dateTo = UtilsService.dateToStringTechnical(new Date(`${year + spread}-${month}-${day}`));

            this.from = new Date(this.dateFrom);
            this.to = new Date(this.dateTo);
            this.now = this.from;

        }
        SpacekitService.setDate(this.now);
        SpacekitService.setSpeed(this.speed);

        this.dateNow = UtilsService.dateToStringTechnical(this.now);
        VisualisationService.setCurrentDate(this.now);
        VisualisationService.setPlaybackSpeed(this.speed);

        setTimeout(() => {
            const start = TimeFrameService.getTimeFrame().start;
            const end = TimeFrameService.getTimeFrame().end;
            const loadedState = {...MemoryService.getToolState()};
            const newdate = new Date(loadedState.activeCurrentDate);

            if((loadedState.activeCurrentDate) && (this.now !== newdate)){
                VisualisationService.setCurrentDate(loadedState.activeCurrentDate);
                this.now = newdate;
                SpacekitService.setDate(newdate);
                VisualisationService.setNow(newdate);
                this.dateNow = UtilsService.dateToStringTechnical(newdate, true);
            }

            if ((TimeFrameService.getTimeFrame().start && TimeFrameService.getTimeFrame().end) && (start!==this.dateFrom || end!==this.dateTo)){
                this.dateFrom = start;
                this.dateTo = end;
            }

            if ((loadedState.speed) && (this.speed !== loadedState.speed)){
                VisualisationService.setPlaybackSpeed(loadedState.speed);
                this.speed = loadedState.speed;
                SpacekitService.setSpeed(this.speed);
            }
        }, 1000); 
    }
}

</script>

<style lang="scss">
@import "@/styles/sovt/timeline.scss";
</style>