<template>
    <div class="ovt__objects-table">
        <div class="ovt__objects-table__box">
            <button class="link ovt__objects-table__button" @click="configureObjects()">
                <i class="icon-settings" /><span>{{ $t.configureYourOwnListOfObjects }}</span>
            </button>
        </div>
        <div v-if="finalAsteroidsList.length" class="ovt__objects-table__content" :class="{loading}">
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.object }}</div>
                <div class="d-table-cell-empty"></div>
                <div class="d-table-cell orbit-switcher label">{{ $t.orbitSwitchLabel }} <Tooltip position="left" :tooltipText="$keys.t.switchOrbit"><i class="icon-info" /></Tooltip></div>
                <div class="d-table-cell">{{ $t.show }}</div>
            </div>
            <div class="inner">
                <div class="d-table-row item" v-for="(item, index) of finalAsteroidsList" :key="index" :class="{'item-highlight': isSelectedObject(item)}">
                    <div class="d-table-cell">
                        <input type="radio" :disabled="getItemDisabled(item)" :id="'radio_' + item.id" :value="item.id" name="radioGroup" :checked="isSelectedObject(item)" @change="handleSelection(item)" /> 
                    </div>
                    <div class="d-table-cell">
                        <span @click="handleSelection(item)" :style="{ color: getItemColor(item) }">
                            {{ displayName(item) }}
                        </span>
                    </div>
                    <div class="d-table-cell orbit-switcher field">
                        <input class="toggle-switch" :id="`perturbedOrbit_${item.id}`" :checked="item.perturbedOn" :disabled="getItemDisabled(item, 'toggle-switch')" type="checkbox" @change="orbitSwitchOnChange(item, $event.target.checked)" />
                        <label class="toggle-switch__button" :class="{disabled: getItemDisabled(item, 'toggle-switch')}" :for="`perturbedOrbit_${item.id}`"></label>
                    </div>
                    <div class="d-table-cell input">
                        <input type="checkbox" :disabled="getItemDisabled(item)" :id="item.name" :checked="isCheckedObject(item)" @change="checkObject(item)" />
                    </div>   
                </div>
            </div>
        </div>
        <div v-if="finalAsteroidsList.length" class="ovt__objects-table__buttons">
            <button v-if="!focused" class="link ovt__objects-table__button" :class="{disabled: !selectedObjectName}" @click="focusOnSelectedObject()">
                <i class="icon-crosshair" /><span>{{ $t.focusOnSelectedObject }}</span>
            </button>
            <button v-if="focused" class="link ovt__objects-table__button" @click="focusOnSelectedObject(true)">
                <i class="icon-maximize" /><span>{{ $t.focusOnTheCenter }}</span>
            </button>
            <p class="ovt__objects-table__right-mouse-button units"><Tooltip position="left" :tooltipText="$t.useRightMouseButton"><div class="right-mouse-button__icon" /></Tooltip></p>
        </div>
    </div>
</template>

<script>
import PopupService from '@/services/popup.service'
import ObjectsService from '@/services/objects.service'
import VisualisationService from '@/services/visualisation.service'
import Tooltip from '@/components/common/Tooltip'
import config from '@/constants/config'

export default {
    name: 'OrbitObjectsTable',
    props: {
        tool: String
    },
    components: {
        Tooltip
    },
    computed: {
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        isChecked() {
            return VisualisationService.getIsChecked();
        },
        selectedObjectName() {
            return VisualisationService.getSelectedObjectName();
        },
        focused() {
            return VisualisationService.getOrbitViewerFocusedOnObject();
        },
        loading() {
            return VisualisationService.loadingObjectsEphemerides().length;
        },
        perturbedOrbitsListLength() {
            return VisualisationService.getIsPerturbed().length;
        }
    },
    methods: {
        getItemDisabled(item, type) {
            if (((item.code == "non-NEO" || item.keplerianOnly) && type == 'toggle-switch')  || (item.sourceType.name == "NEOCP")) {
                return true;
            } else 
                return false;
        },
        getItemColor(item) {
            const settings = config.visualisation.settings;
            if (item.code == "non-NEO") {
                return settings.nonNEAcolor;
            } else if (item.sourceType.name == "NEOCP"){
                return settings.NEOCPcolor;
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },
        configureObjects() {
            PopupService.show({
                component: 'PopupObjectsConfiguration',
            });
        },
        focusOnSelectedObject(center = false) {
            if (center) {
                VisualisationService.focusOnCenter();
            } else {
                VisualisationService.focusOnSelectedObject();
            }
        },
        handleSelection(item) {
            if (item.sourceType.name != "NEOCP"){
                this.selectObject(item);
                this.toggleSelected(item);
            }
        },
        selectObject(item) {
            const designator = item.number ? item.number : item.name;
            this.selectedObjectName = designator === this.selectedObjectName ? '' : designator;
            VisualisationService.selectObject(designator + '', item.perturbedOn);
        },
        isSelectedObject(item) {
            const designator = item.number ? item.number : item.name;
            return designator+'' === this.selectedObjectName;
        },
        toggleSelected(item) {
            this.isSelectedObject(item) ? (this.selectedObjectName = '') : (this.selectedObjectName = item.number ? item.number : item.name);
        },

        checkObject(item, reload = false) {
            const designator = item.number ? item.number : item.name;
            VisualisationService.checkObject(designator+'', item.perturbedOn, reload);
        },
        isCheckedObject(item) {
            const designator = item.number ? item.number : item.name;
            return this.isChecked.indexOf(designator+'') > -1;
        },
        orbitSwitchOnChange(item, value) {
            item.perturbedOn = value;
            if (this.isCheckedObject(item)) {
                this.checkObject(item, true);
            }
        }
    },
    watch: {
        finalAsteroidsList: {
            deep: true,
            handler(newVal) {
                VisualisationService.verifyFinalAsteroidList(newVal);
                const perturbedOrbitsLenght = newVal.filter(e => e.perturbedOn == true).length;
                const allPerturbedOrbitsLength = VisualisationService.getIsPerturbed().length;
                VisualisationService.tooglePerturbedPropagation(perturbedOrbitsLenght + allPerturbedOrbitsLength === 0);
            }
        },
        perturbedOrbitsListLength(orbitListLength) {
            const perturbedOrbitsLength = ObjectsService.getFinalAsteroidsList().filter(asteroid => asteroid.perturbedOn).length;
            VisualisationService.tooglePerturbedPropagation(orbitListLength + perturbedOrbitsLength === 0);
        },
    },
    mounted() {
        if (this.tool === 'scd' || this.tool === 'ovt') {
            //ObjectsService.setUserDefinedObjects(true);
        }

        const queryDesignator = this.$route.query.object;
        if (queryDesignator) {
            ObjectsService.findAsteroidByPhrase(queryDesignator, true);
        }
    }
}
</script>

<style>
    .d-table-cell-empty {
        width: 50px; /* Adjust the width value as needed */
    }
</style>
